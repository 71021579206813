
import { mainAxios } from '../axios-instance';
let list = [];
export default {


  async getAll(input) {
    let result = await mainAxios.get(`/Process`, {
      params: {
        categoryId: input.categoryId, skip: input.skip,
        roleId: input.roleId,
        take: input.take,
        searchKey: input.searchKey,
        sortBy: input.sortBy,
        status: input.status
      }
    });
    if (result.status == 200) {
      if (input.isPage) {
        let arra = Object.assign([], list[0].records);

        list = [];
        list.push({
          records: arra.concat(result.data.records),
          total: result.data.total
        })
        return list
      } else {
        list = [];
        list.push(result.data)
        return list
      }
    }

  },
  async getHomeProcess(input) {
    let result = await mainAxios.get(`/Process/top5`, {
      params: input,
    });
    return result.data;

  },
  async getCommonProcess(input) {
    let result = await mainAxios.get(`/Process/common`, {
      params: input,
    });
    return result.data;

  },
  async getAllProcess(input) {
    let result = await mainAxios.get(`/Process`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/Process`, input);
    return result;
  },
  async update(input, id) {
    let result = await mainAxios.put(`/Process`, input, { params: { processId: id } });
    return result;
  },

  async addFeature(input, id) {
    let result = await mainAxios.post(`/Process/${id}/feature`, input);
    return result;
  },
  async updateFeature(input, id) {
    let result = await mainAxios.put(`/Process/feature`, input, { params: { featureId: id } });
    return result;
  },
  async delete(input) {
    let result = await mainAxios.put(`/Process/status`, null, {
      params: { processId: input.processId, status: input.status },
    });
    return result;
  },
  async deleteFeature(id) {
    let result = await mainAxios.delete(`/Process/feature?featureId=${id}`);
    return result;
  },

  async getProcessById(id) {
    let result = await mainAxios.get(`/Process/${id}`);
    result.data[0].features = result.data[0].features.sort((a, b) => {
      return parseInt(a.order) - parseInt(b.order);
    });
    return result.data;

  },
  async getFeatureById(id) {
    let result = await mainAxios.get(`/Process/${id}/feature`);

    result.data = result.data.sort((a, b) => {
      return parseInt(a.order) - parseInt(b.order);
    });
    return result.data;

  },

}