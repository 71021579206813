<template>
  <div>
    <!-- <div>
      <v-carousel hide-delimiters class="banner" cycle show-arrows-on-hover>
        <v-carousel-item>
          <div class="container">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4 align-self-center">
                  <div class="left-content">
                    <div class="row">
                      <div class="col-md-12">
                        <h2>Industry’s First Simplified Automation Store</h2>
                        <p class="textsize">
                          Financial cooperative, controlled by its members and
                          operated on the principle of people helping people,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 w-full image-blur">
                  <div class="right-image">
                    <img
                      src="../assets/Slider_1.png"
                      alt=""
                      style="object-fit: cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-carousel-item>
        <v-carousel-item>
          <div class="container">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4 align-self-center">
                  <div class="left-content">
                    <div class="row">
                      <div class="col-md-12">
                        <h2>First Simplified Bot Store</h2>
                        <p class="textsize">
                          Financial cooperative, controlled by its members
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 w-full image-blur">
                  <div class="right-image">
                    <img src="../assets/Slider_2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-carousel-item>
        <v-carousel-item>
          <div class="container">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4 align-self-center">
                  <div class="left-content">
                    <div class="row">
                      <div class="col-md-12">
                        <h2>Industry’s First Simplified Dashboard</h2>
                        <p class="textsize">
                          Financial cooperative, controlled by its members and
                          operated on the principle of people helping people,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 w-full image-blur">
                  <div class="right-image">
                    <img src="../assets/Slider_3.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
      <div class="col-md-12 right-image-bg"></div>
    </div>
    <div class="d-md-flex container align-center get-started">
      <v-btn
        color="black"
        class="white--text mt-5 text-left text-capitalize"
        :class="{
          'mr-0': $vuetify.breakpoint.smAndDown,
          'mr-2': $vuetify.breakpoint.mdAndUp,
        }"
        to="/processes"
      >
        Lets Get Started
        <v-avatar right size="18" class="ml-2 text-right">
          <img src="../assets/Arrow.png" />
        </v-avatar>
      </v-btn>
    </div> -->
    <div class="homebg" v-if="isLoggedIn != undefined">
      <div class="container pb-0 pt-0">
        <div class="div-wrapper d-flex justify-space-between">
          <div class="align-self-center">
            <p class="maintext mb-0">start safe. scale smart</p>
            <p class="textsize mb-0">
              Join, Select and Order- Rest is assured.
            </p>
            <div class="d-flex mt-12">
              <v-btn
                class="black--text text-left text-capitalize"
                large
                depressed
                to="/processes"
              >
                Explore Now
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
              <v-btn
                class="text-left lets-talk custom-transform-class text-none"
                large
                depressed
                text
                to="/processes"
              >
                Let's talk about this
                <v-icon right> mdi-arrow-right-thin </v-icon>
              </v-btn>
            </div>
          </div>
          <img class="hidden-sm-and-down" src="../assets/Cube.png" />
        </div>
      </div>
    </div>
    <div class="homebgw" v-else>
      <div class="container pb-0 pt-0">
        <div class="div-wrapper d-flex justify-space-between">
          <div class="align-self-center">
            <p class="maintextw mb-0">
              Automated workflows that<br />helps to meet your business goals
              faster!
            </p>
            <!-- <p class="maintextw mb-0">
              helps to meet your business goals faster!
            </p> -->
            <p class="textsizew mb-0">
              Join, Select and Order- Rest is assured.
            </p>
            <div class="d-flex mt-12">
              <v-btn
                color="secondary"
                class="white--text text-left text-capitalize"
                large
                depressed
                to="/processes"
              >
                Explore Now
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
              <v-btn
                class="
                  secondary--text
                  text-left
                  lets-talk
                  custom-transform-class
                  text-none
                "
                large
                depressed
                text
                to="/processes"
              >
                Let's talk about this
                <v-icon right> mdi-arrow-right-thin </v-icon>
              </v-btn>
            </div>
          </div>
          <img class="hidden-sm-and-down" src="../assets/Cubew.png" />
        </div>
      </div>
    </div>
    <div class="container mt-8">
      <h3 class="workflow-header">Recently added Workflows</h3>
      <div class="row mt-2">
        <!-- <div class="col-md-3" v-for="image in processRec" :key="image.id">
          <router-link :to="`processes/${image.id}`">
            <v-hover v-slot:default="{ hover }">
              <v-card class="cardrt">
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="image.imageURL"
                  :alt="image.name"
                >
                  <v-fade-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        flex-column
                        transition-fast-in-fast-out
                        primary
                        darken-2
                        v-card--reveal
                        white--text
                      "
                      style="height: 100%"
                    >
                      <v-chip
                        label
                        class="fill-width"
                        v-if="image.noImplemented > 0"
                      >
                        {{ imp(image.noImplemented) }}
                      </v-chip>
                      <v-chip label class="fill-width" v-else>
                        Be the first to Order
                      </v-chip>
                    </div>
                  </v-fade-transition>
                </v-img>
                <v-card-title>
                  <h6 class="fill-width">{{ image.name }}</h6>
                </v-card-title>

                <v-card-subtitle>
                  <div class="d-flex">
                    <v-rating
                      :value="parse(image.rating)"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="18"
                      background-color="#ccc"
                    ></v-rating>
                    <small class="grey--text text-sm my-1"
                      >{{ image.rating }} ({{ image.reviews }} reviews)</small
                    >
                  </div>
                </v-card-subtitle>
              </v-card>
            </v-hover>
          </router-link>
        </div> -->
        <div class="col-md-3" v-for="image in processRec" :key="image.id">
          <v-hover v-slot:default="{ hover }">
            <v-card class="cardrt">
              <v-avatar tile class="ml-4 mt-4" size="46">
                <v-img :src="image.imageURL" :alt="image.name"> </v-img>
              </v-avatar>

              <v-card-text class="px-4 pb-2">
                <h6 class="fill-width processtitle mt-2 mb-2">
                  {{ image.name }}
                </h6>

                <v-chip
                  label
                  class="fill-width text-capitalize btnclr textclr mb-2"
                  v-if="image.noImplemented > 0"
                >
                  {{ imp(image.noImplemented) }}
                </v-chip>
                <v-chip label class="fill-width btnclr textclr mb-2" v-else>
                  Be the First to Order
                </v-chip>

                <div class="d-flex justify-space-between">
                  <v-rating
                    :value="parse(image.rating)"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="15"
                    background-color="#ccc"
                  >
                  </v-rating>
                  <small class="grey--text text-sm my-1"
                    >{{ parse(image.rating) }} ({{
                      image.reviews
                    }}
                    reviews)</small
                  >
                </div>
              </v-card-text>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute z-index="0" class="overlay">
                  <v-btn
                    small
                    rounded
                    color="#ffffff"
                    class="text-capitalize mr-2 black--text btn-rnd"
                    @click="goToList(image)"
                    >open<v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h3 class="workflow-header">Top Rated Workflows</h3>
      <div class="row mt-2">
        <!-- <div class="col-md-3" v-for="image in processRat" :key="image.id">
          <router-link :to="`processes/${image.id}`">
            <v-hover v-slot:default="{ hover }">
              <v-card class="cardrt">
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="image.imageURL"
                  :alt="image.name"
                >
                  <v-fade-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        flex-column
                        transition-fast-in-fast-out
                        primary
                        darken-2
                        v-card--reveal
                        white--text
                      "
                      style="height: 100%"
                    >
                      <v-chip
                        label
                        class="fill-width"
                        v-if="image.noImplemented > 0"
                      >
                        {{ imp(image.noImplemented) }}
                      </v-chip>
                      <v-chip label class="fill-width" v-else>
                        Be the first to Order
                      </v-chip>
                    </div>
                  </v-fade-transition>
                </v-img>
                <v-card-title>
                  <h6 class="fill-width">{{ image.name }}</h6>
                </v-card-title>

                <v-card-subtitle>
                  <div class="d-flex">
                    <v-rating
                      :value="parse(image.rating)"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="18"
                      background-color="#ccc"
                    ></v-rating>
                    <small class="grey--text text-sm my-1"
                      >{{ image.rating }} ({{ image.reviews }} reviews)</small
                    >
                  </div>
                </v-card-subtitle>
              </v-card>
            </v-hover>
          </router-link>
        </div> -->
        <div class="col-md-3" v-for="image in processRat" :key="image.id">
          <v-hover v-slot:default="{ hover }">
            <v-card class="cardrt">
              <v-avatar tile class="ml-4 mt-4" size="46">
                <v-img :src="image.imageURL" :alt="image.name"> </v-img>
              </v-avatar>

              <v-card-text class="px-4 pb-2">
                <h6 class="fill-width processtitle mt-2 mb-2">
                  {{ image.name }}
                </h6>

                <v-chip
                  label
                  class="fill-width text-capitalize btnclr textclr mb-2"
                  v-if="image.noImplemented > 0"
                >
                  {{ imp(image.noImplemented) }}
                </v-chip>
                <v-chip label class="fill-width btnclr textclr mb-2" v-else>
                  Be the First to Order
                </v-chip>

                <div class="d-flex justify-space-between">
                  <v-rating
                    :value="parse(image.rating)"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="15"
                    background-color="#ccc"
                  >
                  </v-rating>
                  <small class="grey--text text-sm my-1"
                    >{{ parse(image.rating) }} ({{
                      image.reviews
                    }}
                    reviews)</small
                  >
                </div>
              </v-card-text>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute z-index="0" class="overlay">
                  <v-btn
                    small
                    rounded
                    color="#ffffff"
                    class="text-capitalize mr-2 black--text btn-rnd"
                    @click="goToList(image)"
                    >open<v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h3 class="workflow-header">Most Implemented Workflows</h3>
      <div class="row mt-2">
        <!-- <div class="col-md-3" v-for="image in processImp" :key="image.id">
          <router-link :to="`processes/${image.id}`">
            <v-hover v-slot:default="{ hover }">
              <v-card class="cardrt">
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="image.imageURL"
                  :alt="image.name"
                >
                  <v-fade-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        flex-column
                        transition-fast-in-fast-out
                        primary
                        darken-2
                        v-card--reveal
                        white--text
                      "
                      style="height: 100%"
                    >
                      <v-chip
                        label
                        class="fill-width"
                        v-if="image.noImplemented > 0"
                      >
                        {{ imp(image.noImplemented) }}
                      </v-chip>
                      <v-chip label class="fill-width" v-else>
                        Be the first to Order
                      </v-chip>
                    </div>
                  </v-fade-transition>
                </v-img>
                <v-card-title>
                  <h6 class="fill-width">{{ image.name }}</h6>
                </v-card-title>

                <v-card-subtitle>
                  <div class="d-flex">
                    <v-rating
                      :value="parse(image.rating)"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="18"
                      background-color="#ccc"
                    ></v-rating>
                    <small class="grey--text text-sm my-1"
                      >{{ image.rating }} ({{ image.reviews }} reviews)</small
                    >
                  </div>
                </v-card-subtitle>
              </v-card>
            </v-hover>
          </router-link>
        </div> -->
        <div class="col-md-3" v-for="image in processImp" :key="image.id">
          <v-hover v-slot:default="{ hover }">
            <v-card class="cardrt">
              <v-avatar tile class="ml-4 mt-4" size="46">
                <v-img :src="image.imageURL" :alt="image.name"> </v-img>
              </v-avatar>

              <v-card-text class="px-4 pb-2">
                <h6 class="fill-width processtitle mt-2 mb-2">
                  {{ image.name }}
                </h6>

                <v-chip
                  label
                  class="fill-width text-capitalize btnclr textclr mb-2"
                  v-if="image.noImplemented > 0"
                >
                  {{ imp(image.noImplemented) }}
                </v-chip>
                <v-chip label class="fill-width btnclr textclr mb-2" v-else>
                  Be the First to Order
                </v-chip>

                <div class="d-flex justify-space-between">
                  <v-rating
                    :value="parse(image.rating)"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="15"
                    background-color="#ccc"
                  >
                  </v-rating>
                  <small class="grey--text text-sm my-1"
                    >{{ parse(image.rating) }} ({{
                      image.reviews
                    }}
                    reviews)</small
                  >
                </div>
              </v-card-text>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute z-index="0" class="overlay">
                  <v-btn
                    small
                    rounded
                    color="#ffffff"
                    class="text-capitalize mr-2 black--text btn-rnd"
                    @click="goToList(image)"
                    >open<v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </div>
      </div>
    </div>
    <div class="container text-center mt-12 mb-12">
      <div class="seperator">
        <h5>
          <v-btn
            class="black--text text-left text-capitalize"
            large
            depressed
            outlined
            to="/processes"
          >
            Browse All
            <v-icon right> mdi-plus </v-icon>
          </v-btn>
        </h5>
      </div>
    </div>
    <v-card class="container mt-12">
      <div class="workflow">
        <v-row class="d-flex div-wrapper1">
          <v-col md="4" sm="12" lg="4" class="align-self-center px-12">
            <v-icon color="yellow">mdi-lightning-bolt</v-icon>
            <p class="essential mt-3">Common Workflows</p>
            <p class="essential-text">
              Take your business experience to new heights with must-have
              workflows
            </p>

            <router-link
              :to="{
                name: 'ProductList',

                params: { message: 'Common' },
              }"
            >
              <v-btn
                color="primary"
                dark
                class="text-capitalize mt-5"
                depressed
              >
                Let's try it now<v-icon right> mdi-arrow-right-thin </v-icon>
              </v-btn>
            </router-link>
          </v-col>
          <v-col md="8" lg="8" sm="12" class="align-self-center">
            <v-slide-group dark v-model="model" center-active>
              <!-- <v-slide-item v-slot:default="{ active, toggle }">
              <v-card class="ma-2" max-width="200" @click="toggle">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="require('@/assets/alignxcel.svg')"
                >
                  <v-card-title>Top 10 Australian beaches</v-card-title>
                </v-img>

                <v-card-subtitle class="pb-0">Number 10</v-card-subtitle>

                <v-card-text class="text--primary">
                  <div>Whitehaven Beach</div>

                  <div>Whitsunday Island, Whitsunday Islands</div>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="orange" text> Share </v-btn>

                  <v-btn color="orange" text> Explore </v-btn>
                </v-card-actions>
                <v-row class="fill-height" align="center" justify="center">
                  <v-scale-transition>
                    <v-icon
                      v-if="active"
                      color="white"
                      size="48"
                      v-text="'mdi-close-circle-outline'"
                    ></v-icon>
                  </v-scale-transition>
                </v-row>
              </v-card>
            </v-slide-item> -->

              <v-slide-item
                v-for="image in processCommon"
                :key="image.id"
                class="pa-0"
              >
                <!-- <div class="col-md-3 col-lg-3">
                <router-link :to="`processes/${image.id}`"> -->
                <v-card class="ma-4" light width="250">
                  <v-avatar tile class="ml-4 mt-4" size="46">
                    <v-img :src="image.imageURL" :alt="image.name"> </v-img>
                  </v-avatar>

                  <v-card-text class="px-4 pb-2">
                    <h6 class="fill-width processtitle mt-2 mb-2">
                      {{ image.name }}
                    </h6>

                    <v-chip
                      label
                      class="fill-width text-capitalize btnclr textclr mb-2"
                      v-if="image.noImplemented > 0"
                    >
                      {{ imp(image.noImplemented) }}
                    </v-chip>
                    <v-chip label class="fill-width btnclr textclr mb-2" v-else>
                      Be the First to Order
                    </v-chip>

                    <!-- <div class="d-flex justify-space-between">
                      <v-rating
                        :value="parse(image.rating)"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="15"
                        background-color="#ccc"
                      >
                      </v-rating>
                      <small class="grey--text text-sm my-1"
                        >{{ parse(image.rating) }} ({{
                          image.reviews
                        }}
                        reviews)</small
                      >
                    </div> -->
                    <div class="d-flex justify-space-between">
                      <v-rating
                        :value="parse(image.rating)"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="15"
                        background-color="#ccc"
                      >
                        <template v-slot:item="props">
                          <v-icon
                            size="14"
                            :color="props.isFilled ? 'amber' : 'grey lighten-1'"
                          >
                            {{
                              props.isFilled
                                ? "mdi-star-circle"
                                : "mdi-star-circle"
                            }}
                          </v-icon>
                        </template>
                      </v-rating>
                      <small class="grey--text text-sm my-1"
                        >{{ parse(image.rating) }} ({{
                          image.reviews
                        }}
                        reviews)</small
                      >
                    </div>
                    <v-btn
                      @click="goToList(image)"
                      class="
                        text-capitalize
                        btn-block
                        btnflow-text btnflow
                        my-2
                      "
                    >
                      Explore Now!
                    </v-btn>
                  </v-card-text>
                  <!-- <v-card-subtitle> </v-card-subtitle> -->
                </v-card>
                <!-- <v-card class="ma-4" light width="250">
                  <v-img
                    class="white--text align-end pa-0"
                    height="200px"
                    :src="image.imageURL"
                    :alt="image.name"
                  >
                    <v-chip
                      label
                      class="
                        fill-width
                        text-capitalize
                        btnclr-flow
                        textclr-flow
                        mb-2
                        ml-4
                      "
                    >
                      {{ image.categoryName }}
                    </v-chip>
                  </v-img>
                  <v-card-title>
                    <h6 class="fill-width processtitle mt-2 mb-2">
                      {{ image.name }}
                    </h6>
                  </v-card-title>

                  <v-card-subtitle>
                    <div class="d-flex justify-space-between">
                      <v-rating
                        :value="parse(image.rating)"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="15"
                        background-color="#ccc"
                      >
                        <template v-slot:item="props">
                          <v-icon
                            size="14"
                            :color="props.isFilled ? 'amber' : 'grey lighten-1'"
                          >
                            {{
                              props.isFilled
                                ? "mdi-star-circle"
                                : "mdi-star-circle"
                            }}
                          </v-icon>
                        </template>
                      </v-rating>
                      <small class="grey--text text-sm my-1"
                        >{{ parse(image.rating) }} ({{
                          image.reviews
                        }}
                        reviews)</small
                      >
                    </div>
                    <v-btn
                      @click="goToList(image)"
                      class="
                        text-capitalize
                        btn-block
                        btnflow-text btnflow
                        mt-2
                      "
                    >
                      Explore Now!
                    </v-btn>
                  </v-card-subtitle>
                </v-card> -->
              </v-slide-item>
            </v-slide-group>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <div class="container text-center mt-16">
      <p class="getstart">
        Get started with
        <label class="getstartb">smart, safe and simplied solution</label>
      </p>
      <p class="getstarttext">
        Boost your productivity. Start using workflow today.
      </p>
      <v-btn
        color="primary"
        dark
        class="text-capitalize mt-5"
        to="/processes"
        depressed
      >
        Let's try it now
      </v-btn>
      <div>
        <img :src="require('@/assets/poweredby.svg')" height="52" contains />
      </div>
    </div>
    <div class="text-center mt-8 mb-0">
      <v-img :src="require('@/assets/alignxcel.svg')" contains></v-img>
    </div>
  </div>
</template>
<script>
import processService from "@/services/process.service.js";
import authService from "@/services/auth.service.js";
export default {
  created() {
    this.getHomeProcessRec();
    this.getHomeProcessRat({
      type: "Rating",
    });
    this.getHomeProcessImp({
      type: "NoImplemented",
    });
    this.getCommonProcess();
  },
  data() {
    return {
      processImp: [],
      processRec: [],
      processRat: [],
      processCommon: [],
      loadingimp: false,
      loadingrec: false,
      loadingrat: false,
      isLoggedIn: authService.isLoggedIn ? true : undefined,
      model: null,
    };
  },
  computed: {},
  methods: {
    imp(item) {
      if (item == 1) {
        return "Implemented : " + item + " customer";
      } else {
        return "Implemented : " + item + " customers";
      }
    },
    goToList(image) {
      this.$router.push(`processes/${image.id}`);
    },
    parse(item) {
      let val = item.toFixed(2);
      return Number(val);
    },

    async getHomeProcessImp(input) {
      let result = await processService.getHomeProcess(input);

      this.processImp = result;
    },

    async getCommonProcess(input) {
      let result = await processService.getCommonProcess(input);

      this.processCommon = result;
    },

    async getHomeProcessRec(input) {
      let result = await processService.getHomeProcess(input);

      this.processRec = result;
    },
    async getHomeProcessRat(input) {
      let result = await processService.getHomeProcess(input);

      this.processRat = result;
    },

    truncateText: (text) => {
      if (text.length > 60) {
        text = text.substring(0, 125) + "...";
      }

      return text;
    },
  },
};
</script>

<style  scoped lang="scss">
@import "./src/colors.scss";
.main-banner .left-content h2 {
  z-index: 2;
  font-weight: 700;
  font-size: 30px;
  color: #2a2a2a;
  margin-bottom: 20px;
}

.main-banner .left-content p {
  margin-bottom: 30px;
  margin-right: 45px;
}
.right-image-bg {
  background-image: url(../assets/Bottom_overlay.png);
  background-repeat: no-repeat;
  margin-top: -80px;
  position: relative;
  width: 100%;
  opacity: 1;
  left: 0px;
  height: 200px;
}
.main-banner .right-image {
  text-align: right;
  position: relative;
  z-index: 1;
}
.banner {
  z-index: 1;
}
.image-blur {
  padding-top: 60px;
  background-size: cover;
}
.main-banner .right-image img {
  width: 100%;
}

.get-started {
  margin-top: -100px;
}
.bg {
  background-color: #acf6c7f4;

  opacity: 1;
}
.div-wrapper {
  position: relative;
  height: 300px;
}
@media (min-width: 960px) {
  .div-wrapper1 {
    position: relative;
    height: 492px;
  }
  .workflow {
    height: 492px;

    background-color: #020d17 !important;
    background: url(../assets/workflow.png) no-repeat;
    border-radius: 12px !important;
  }
}
.div-wrapper1 {
  position: relative;
  // height: 492px;
}

.div-wrapper img {
  // height: 50%;
  // position: absolute;
  right: 0;
  bottom: 0;
  opacity: 1;
}
.imgsize {
  height: 18px !important;
  width: 18px !important;
}
.btnpos {
  position: absolute;
  left: 20px;
  bottom: 20px;
  opacity: 1;
}
.textpos {
  position: absolute;
  top: 20%;
  left: 20%;
  opacity: 1;
}
.seperator h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seperator h5::before,
.seperator h5::after {
  content: "";
  display: block;
  flex-grow: 1;
  height: 1px;
  background: #e3e4e8;
}

.seperator h5 .btnmargin {
  padding: 0 2em;
}
.textsize {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.textsizew {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: $secondary;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.cardrt {
  border: gray !important;

  border: 1px solid rgb(231, 221, 221) !important;
}
.fill-width {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// .v-card--reveal {
//   align-items: center;
//   bottom: 0;
//   justify-content: center;
//   opacity: 0.8;
//   position: absolute;
//   width: 100%;
// }
.v-card--reveal {
  background-color: #020d17;
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  border-radius: 8px;
}
.v-application--wrap {
  min-height: 100vh;
  justify-content: center;
}

.workflow-header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: $secondary;
}
.processtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #222222;
}
.lets-talk {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: $white !important;
}
.cardrt:hover {
  background: #ffffff;
  box-shadow: none !important;
  border: 2px solid $primary !important;
}
.overlay {
  // opacity: 0.8 !important;
  background-color: rgba(23, 24, 29, 0.8);
  border-color: rgba(23, 24, 29, 0.8);
  background: rgba(23, 24, 29, 0.8) !important;
  backdrop-filter: blur(4px);
}
.btnclr {
  height: 24px !important;
  justify-content: center;
  align-items: center;
  padding: 4px 6px !important;
  gap: 8px !important;
  background: $secondary !important;
  border-radius: 4px !important;
}
.textclr {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: $white !important;
}
.btnclr-flow {
  height: 24px !important;
  justify-content: center;
  align-items: center;
  padding: 4px 8px !important;
  gap: 8px !important;
  background: #ebecf6 !important;
  border-radius: 4px !important;
}
.textclr-flow {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #303f9f !important;
}
/deep/.v-rating--dense .v-icon {
  padding: 0;
}
.homebg {
  background: url(../assets/Banner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: 100%;
  // height: 392px;
}
.homebgw {
  background: url(../assets/homeBG.svg);
  background-repeat: no-repeat;
  // background-size: 100%;
  position: relative;
  // width: 100%;
  // height: 392px;
}
.maintext {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 47px;
  color: #ffffff;
}
.maintextw {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 47px;
  color: $secondary;
}
.div-wrapper img {
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 1;
}
.right-image {
  height: 100%;
  right: 0;
}
.getstart {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: $secondary;
}
.getstarttext {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $getstarttext;
}
.getstartb {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: $secondary;
}
.workflow {
  // height: 492px;

  background-color: #020d17 !important;
  background: url(../assets/workflow.png) no-repeat;
  border-radius: 12px !important;
}
.essential {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: $white;
}
.essential-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #e2e5f4;
}
.btnflow {
  background: rgba(23, 24, 29, 0.1);
  mix-blend-mode: normal;

  border-radius: 4.28108px;
}
.btnflow-text {
  font-weight: 500;
  font-size: 14.9838px;
  line-height: 21px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Secondary/500 */

  color: #17181d;
}
.btn-rnd {
  border-radius: 25px !important;
}
</style>
