import axios from "axios";
import authService from './services/auth.service';
import apiurl from './apiurl.js';
import storedtoken from "@/services//alignApiToken.js";


let alignAPIURL = apiurl.alignAPIURL;
let apiURL = apiurl.apiURL;
let token = window.localStorage.getItem('ppToken');

const mainAxios = axios.create({
  baseURL: apiURL,
  headers: { 'Authorization': `Bearer ${token}` }
});
mainAxios.interceptors.request.use((config) => {

  const token = window.localStorage.getItem('ppToken');
  config.headers.Authorization = `Bearer ${token}`;
  return config;

});
let token1 = storedtoken.token;
const customAxios = axios.create({
  baseURL: alignAPIURL,
  headers: { 'Authorization': `Bearer ${token1}` }
});
customAxios.interceptors.request.use((config) => {

  const token = storedtoken.token;
  config.headers.Authorization = `Bearer ${token}`;
  return config;

});
mainAxios.interceptors.response.use(
  (res) => {
    return res;

  },
  (error) => {
    if (error.response.status === 401) {
      authService.logout();
      return error.response;

    }
    else {
      return error.response;
    }


  }
);
customAxios.interceptors.response.use(
  (res) => {
    return res;

  },
  (error) => {
    if (error.response.status === 401) {
      authService.logout();
      return error.response;

    }
    else {
      return error.response;
    }


  }
);
export {
  mainAxios,
  customAxios
};

