<template>
  <hero></hero>
</template>

<script>
import Hero from "../components/Hero.vue";
export default {
  components: { Hero },

  computed: {},

  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
