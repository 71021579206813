
import { mainAxios } from '../axios-instance';
export default {

  async getAll(input) {
    let result = await mainAxios.get(`/Category`, {
      params: input,
    });
    return result.data;

  },
  async create(input) {
    let result = await mainAxios.post(`/Category`, input);
    return result;
  },
  async update(input) {
    let result = await mainAxios.put(`/Category`, input);
    return result;
  },

  async delete(input) {
    let result = await mainAxios.put(`/Category/status`, null, {
      params: { categoryId: input.categoryId, status: input.status },
    });
    return result;
  },

}